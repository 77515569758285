<template>
  <v-skeleton-loader v-if="loading" type="card-heading,list-item"></v-skeleton-loader>
    <v-expansion-panels v-else v-model="panel" :readonly="product.data.reloadable && isMissingWallet">
      <v-expansion-panel>
        <v-expansion-panel-header>
          <v-row class="w-100">
            <v-col class="pa-0 pl-2 d-flex flex-column justify-center align-start" :class="{'col-8' : product.data.reloadable && !isMobile, 'col-5' : !product.data.reloadable && !isMobile, 'col-12' : isMobile}">
              <div :class="{ 'mt-2  mr-6': isMobile}" v-if="quantity > 0">
								<span class="font-weight-regular mb-2 title" >
                  <span v-if="product.data.reloadable"  class="title">{{quantity}}&nbsp;x&nbsp;</span>{{productName}}
                </span>
              </div>
            </v-col>
            <v-col v-if="!product.data.reloadable" class="d-flex align-center justify-start" :class="{'col-3' : !isMobile, 'col-6 pl-2' : isMobile }">
              <CartProductQuantityPicker
                  :product="product"
                  :cart-item="cartItem"
                  :reload-wallet-id="itemReloadWallets[quantity -1]?.wallet.id || null"
                  in-cart
              />
            </v-col>
            <v-col class="pa-0 d-flex align-center justify-end"  :class="{'col-2' : !isMobile,  'col-3' : isMobile && !product.data.reloadable, 'offset-6 col-3' : isMobile && product.data.reloadable }">
              <div class="font-weight-black title"
                  v-text="$options.filters.currency(subTotal())"
              ></div>
            </v-col>
            <v-col class="pa-0 d-flex justify-center align-center"  :class="{'col-2' : !isMobile, 'col-3' : isMobile }">
              <v-btn
                  class="ma-4 pa-0"
                  color="error"
                  :width="(isMobile && '30px') || '36px'"
                  :height="(isMobile && '30px') || '36px'"
                  icon
                  @click.stop.prevent="handleRemoveClick(cartItem.data.quantity)">
                <v-icon class="pa-0" color="#EF8686">mdi-delete</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-divider class="mb-3"/>
            <v-row v-for="index in cartItem.data.quantity" :key="`${cartItem.data.transactionDetailId}-${index}` " class="ml-1 d-flex align-content-center">
              <v-col class="pa-0 d-flex justify-center align-center col-6">
                <v-row class="d-flex">
                  <v-col class="d-flex flex-grow-1 pr-0">
                    <v-text-field
                        v-if="hasReloadWallet(index -1)"
                        :value="itemReloadWallets[index -1].wallet.current_uid.uid"
                        :rules="[rules.required]"
                        :placeholder="$t('reloadTag.add_uid', {media : mediaName})"
                        :hide-details="!hasReloadWalletAccount(index -1)"
						:persistent-hint="hasReloadWalletAccount(index -1)"
                        :disabled="uidsProcessing.includes(index - 1)"
                        :loading="uidsProcessing.includes(index - 1)"
                        @click:append="detachUid(itemReloadWallets[index -1].wallet.id, index - 1)"
						:hint="hasReloadWalletAccount(index -1) ?  $t('reloadTag.assigned_to', {name : getWalletAccountName(index - 1)}) : undefined"
                        append-icon="mdi-close"
                        prepend-inner-icon="mdi-check"
                        class="wallet-input"
						:class="{'mt-3' : hasReloadWalletAccount(index-1)}"
                        outlined
                        readonly
                        dense
                    />
                    <ReloadUidPicker v-else
						class="mt-3"
						:transactionDetailId="cartItem.data.transactionDetailId"
						:media-name="mediaName"
						:reload-wallets="reloadWallets"
						:required="product.data.reloadable"
						:product-name="productName"
						@invalidUid="(state) => invalidUidSent(state, index - 1)" />
                  </v-col>
                  <v-col class="d-flex shrink pl-0"
                         :class="{
					  		'align-center' : !product.data.reloadable && !hasInvalidOptionalReloadUid(index -1) || (!hasReloadWalletAccount(index -1) && hasReloadWallet(index -1)) ,
					  		'mt-5' : (hasReloadWalletAccount(index -1) || !hasReloadWallet(index -1)) && product.data.reloadable,
					  		'mt-3' : hasInvalidOptionalReloadUid(index -1)
				  	}">
                    <CustomTooltip
                        :text="$options.filters.translatable( $t('reloadTag.default_description'), $root.reloadTag?.data.reloadable_description_i18n, $i18n.locale )"
                        icon="mdi-information-slab-circle-outline"/>
                  </v-col>
                </v-row>
              </v-col>
              <v-col  class="pa-0 d-flex align-center justify-end title" :class="{'col-4' : !isMobile, 'col-3' : isMobile }">
                {{$options.filters.currency(cartItem.data.unitPrice)}}
              </v-col>
              <v-col  class="pa-0 d-flex justify-center align-center" :class="{'col-2' : !isMobile, 'col-3' : isMobile }">
                <v-btn
                    class="ma-4 pa-0"
                    color="error"
                    :width="(isMobile && '30px') || '36px'"
                    :height="(isMobile && '30px') || '36px'"
                    @click.stop.prevent="handleRemoveClick(1, itemReloadWallets[index -1]?.wallet.id || null)"
                    icon>
                  <v-icon class="pa-0" color="#EF8686">mdi-delete</v-icon>
                </v-btn>
              </v-col>
            </v-row>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
</template>

<script>
import ProductMixin, { WindowWidthMixin } from '@/mixins/ProductMixin'
import CustomTooltip from '@/components/CustomTooltip.vue'
import { Rules, EComService, EventBus } from '@connectngo/sdk'
import ReloadUidPicker from '@/components/ReloadV2/ReloadUidPicker.vue'
import globalVariables from '@/global'
import InputNumeric from '@/components/InputNumeric.vue'
import CartProductQuantityPicker from '@/components/CartProductQuantityPicker.vue'
import ReloadV2Mixin from '@/mixins/ReloadV2Mixin'

export default {
  name: "CartReloadProductCard",
  components: { CartProductQuantityPicker, InputNumeric, ReloadUidPicker, CustomTooltip },

  mixins: [ProductMixin, WindowWidthMixin, ReloadV2Mixin],
  props : {
    reloadWallets: {
      type : Array,
      default: () => []
    }
  },
  data() {
    return {
      loading : false,
      mediaName : null,
      rules: {
        required: value => Rules.required(value) || this.$t('reloadTag.uid_required', {media : this.mediaName}),
      },
      panel : 0,
      uidsProcessing : [],
      optionalInvalidUid : []
    }
  },
  computed : {
    productName() {
      return this.$options.filters.translatable(
          this.product.data.name,
          this.product.data.name_i18n,
          this.$i18n.locale
      )
    },
    itemReloadWallets() {
      let walletFormat = [];
      this.cartItem.data.reload_wallets.forEach( wallet => {
        for (let i = 0; i < wallet.quantity; i++) {
          walletFormat.push(wallet)
        }
      })

      return walletFormat;
    },
    isMissingWallet() {
      return this.itemReloadWallets.length !== this.cartItem.data.quantity;
    },
	  isLoggedIn() {
		  return this.$root.user.data.id !== null
	  },
  },

  created() {
    const mediaName = this.$options.filters.translatable(
        this.$root.websiteConfig.data.fields.media_name,
        this.$root.websiteConfig.data.fields.media_name_i18n,
        this.$i18n.locale
    ) || 'UID'

    this.mediaName = mediaName.charAt(0).toUpperCase() + mediaName.slice(1);
  },
  methods : {
    detachUid(walletId, index) {
      this.uidsProcessing.push(index);
      new EComService().detachReloadWalletFromTd(this.$root.cart.data.uuid, this.cartItem.data.transactionDetailId, walletId)
          .then(cart => {
            globalVariables.cart = cart
            EventBus.publish('CART_UPDATED', cart)
          })
          .catch(err => this.$handleError(this, err))
          .finally(() => {
            const tmp = this.uidsProcessing.indexOf(index);
            if (tmp > -1) { // only splice array when item is found
              this.uidsProcessing.splice(tmp, 1); // 2nd parameter means remove one item only
            }
          })

    },
    hasReloadWallet(index) {
      return typeof this.itemReloadWallets[index] !== 'undefined'
    },
    invalidUidSent(state, index) {
      if(this.product.data.reloadable) {
        return;
      }
      if (state) {
        this.optionalInvalidUid.push(index);
      } else {
        // Reset all if valid submitted as the component refresh
        this.optionalInvalidUid = [];
      }
    },
    hasInvalidOptionalReloadUid(index) {
      if(this.product.data.reloadable) {
        return false;
      }
      return this.optionalInvalidUid.indexOf(index) > -1;
    },
	  hasReloadWalletAccount(index) {
		  return this.hasReloadWallet(index) && this.itemReloadWallets[index].wallet.account_current && this.itemReloadWallets[index].wallet.account_current?.firstname !== null
	  },
	  getWalletAccountName(index) {
		return this.walletAccountName(this.itemReloadWallets[index].wallet.account_current)
	  },
  }
}
</script>

<style scoped>
  .v-application .title {
    font-size: 1rem !important;
  }

  .error--text input {
    border-color: red;
  }

  ::v-deep .v-expansion-panel-header__icon {
    position: absolute;
    top: 30px;
    right: 20px;
    @media (max-width:599px) {
      top: 12px;
      right: 12px;
    }
  }
  ::v-deep .v-input__icon--prepend-inner .mdi-check {
    color: #009F79;
  }

  .title {
    font-weight: 500 !important;
    color: #686A71;
    line-height: 1.5rem;
    @media (max-width:599px) {
      font-size: 1rem !important;
    }
  }

  .v-expansion-panel-content .title {
    font-size: 0.9rem !important;
  }

  .wallet-input ::v-deep .v-input__slot fieldset {
    border: 1px solid #CCCDD1 !important;
  }

  ::v-deep .v-text-field.v-text-field--enclosed .v-text-field__details  {
	  margin-bottom: 0 !important;
  }

</style>
