<template>
	<v-sheet data-testid="comboNotification" :color="color" :height="height" :style="cssVars">
		<div class="d-flex fill-height align-center justify-center"><a data-testid="comboAvailableBtn" :class="linkClass" style="text-underline-offset: 4px;" class="white--text" @click="handleComboModalOpenClick">{{$t('cart.combo_available')}}</a></div>
		<component
  			v-if="handleModal"
  			:is="isTimeBasedCombos ? 'ComboModalExperimental' : 'ComboModal'"
  			:showComboModal="showComboModal"
  			:combos="combos"
  			@handleComboModalCloseClick="handleComboModalCloseClick"

		>
		</component>
	</v-sheet>
</template>
<script>
import ComboModal from '@/components/Modals/ComboModal'
import ComboModalExperimental from '@/components/Modals/ComboModalExperimental'

export default {
	name: 'ComboNotification',

	components: { ComboModal, ComboModalExperimental },

	props: {
		color: {
			type: String,
			required: false,
			default: 'success',
		},
		height: {
			type: Number,
			required: false,
			default: 50,
		},
		borderRadius: {
			type: Number,
			required: false,
			default: 0,
		},
		textDecorationUnderline: {
			type: Boolean,
			required: false,
			default: false,
		},
		textH5: {
			type: Boolean,
			required: false,
			default: false,
		},
		textH6: {
			type: Boolean,
			required: false,
			default: false,
		},
		fontWeightBold: {
			type: Boolean,
			required: false,
			default: false,
		},
		combos: {
			type: Array,
			required: false,
			default() {return []},
		},
		handleModal: {
			type: Boolean,
			required: false,
			default: true,
		}
	},

	data: () => ({
		showComboModal: false,
	}),

	methods: {
		handleComboModalOpenClick() {
			this.showComboModal = true;
		},
		handleComboModalCloseClick() {
			this.showComboModal = false;
		}
	},

	computed: {
		cssVars () {
			return {
				'border-radius': this.borderRadius + 'px',
				'width': '100%',
			}
		},
		isTimeBasedCombos() {
			const { feature_flags } = this.$root.websiteConfig.data || {};
			return feature_flags.includes('co-5754-time-based-combos');
		},

		linkClass () {
			return [
				this.textDecorationUnderline ? 'text-decoration-underline' : '',
				this.textH5 ? 'text-h5' : '',
				this.textH6 ? 'text-h6' : '',
				this.fontWeightBold ? 'font-weight-bold' : '',
			]
		}
	},
}
</script>
