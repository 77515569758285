<template>
	<v-skeleton-loader v-if="skeleton" type="card-heading,card-heading,card-heading,card-heading,card-heading"></v-skeleton-loader>
	<v-list v-else v-bind="$attrs" v-on="$listeners">
		<template v-for="(item, itemIdx) in value.results">
			<v-divider v-if="itemIdx > 0" :key="itemIdx + '_div'"></v-divider>
			<v-list-group :value="itemIdx === index" :key="itemIdx + '_group'" no-action  v-if="item.data.products?.length > 0 && !item.data.reloadable">
				<template #activator>
					<v-list-item :key="itemIdx + '_uniq'" :to="getTagLink(item)">
						<v-list-item-content>
							<v-list-item-title class="flexible text-capitalize-fl" v-text="$options.filters.translatable(item.data.name, item.data.name_i18n, $i18n.locale)"></v-list-item-title>
						</v-list-item-content>
					</v-list-item>
				</template>
        <template>
          <v-list-item v-for="(child, childIdx) in item.data.products" :to="getTagChildLink(item, child)" :key="childIdx">
            <v-list-item-content>
              <v-list-item-title class="flexible text-capitalize-fl" v-text="$options.filters.translatable(child.data.name, child.data.name_i18n, $i18n.locale)"></v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </template>
			</v-list-group>
      <v-list-item :to="getTagLink(item)" v-else-if="item.data.products?.length > 0">
        <v-list-item-content>
          <v-list-item-title class="ml-4" v-text="$options.filters.translatable(item.data.name, item.data.name_i18n, $i18n.locale)" />
        </v-list-item-content>
      </v-list-item>
		</template>
	</v-list>
</template>

<script>
import { PaginationModel } from '@connectngo/sdk';
import ReloadV2Mixin from '@/mixins/ReloadV2Mixin'
import globalVariables from '@/global'
import EcomReskinMixin from '@/mixins/EcomReskinMixin'

export default {
	name: 'Categories',
  mixins : [ReloadV2Mixin, EcomReskinMixin],
	props: {
		value: {
			type: PaginationModel,
			default: () => new PaginationModel(),
		},
		skeleton: {
			type: Boolean,
			default: false,
		},
	},

	data: () => ({
		index: null,
	}),

	computed: {
		_index: {
			get() {
				return this.index;
			},
			set(value) {
				this.index = value;
			},
		},
		isTimeBasedCombos() {
			const { feature_flags } = this.$root.websiteConfig.data || {};
			return feature_flags.includes('co-5754-time-based-combos');
		}
	},

	created() {
		if (!this.value) {
			return;
		}

		loopMain: for (let i = 0; i < this.value.results.length; i++) {
			const result = this.value.results[i];

			if (this.$route.name === 'tag'
			&& result.data.slug === this.$route.params.key) {
				this._index = i;
				break loopMain;
			}

			if (result.data.products.length > 0) {
				for (let y = 0; y < result.data.products.length; y++) {
					const product = result.data.products[y];
					if (product.data.slug === this.$route.params.slug) {
						this._index = i;
						break loopMain;
					}
				}
			}
		}
	},
	methods: {
		getTagLink(item) {
      if(!this.reloadV2Enabled || !item.data.reloadable) {
        return {
          name: 'tag',
          params: {
            key: item.data?.slug
          }
        }
      } else {
        return !this.$root.cart.data.reload_wallet_id ?
            {name : 'reloadV2'} :
            { name: 'reloadV2-step', params : {step : 'products'} }
      }
		},
		getTagChildLink(item, child) {
			if (this.ecomReskinFfEnabled) {
				return {
					name: 'tag',
					params: { key: item.data.slug },
					hash : '#' + child.data.slug
				}
			}

			return {
				name: child.data.is_combo && this.isTimeBasedCombos ? 'combo' : 'product',
				params: {
					slug: child.data.slug,
				},
			};
		}
	}
}
</script>

<style lang="scss" scoped>
.v-skeleton-loader ::v-deep .v-skeleton-loader__heading {
	width: 80%;
}
</style>
