<template>
	<Page color="neutralLight" no-sidebar no-top-page>
		<v-container style="max-width: 35rem">
			<h3 class="font-weight-regular text-center ma-4 mb-6 pt-3 pb-2" v-text="$t('loginForm.loginAccount')"></h3>
			<v-card>
				<KYC :route="$route.name" />
			</v-card>
		</v-container>
	</Page>
</template>

<script>
import Page from '@/components/Page';
import KYC from '@/components/KYC';

export default {
	name: 'Register',

	components: { Page, KYC },
}
</script>

<style scoped lang="scss">
h3 {
	font-size: 36px;
}

.btn--underline {
	text-decoration: underline;
	font-size: 24px;
	text-transform: none;
	span {
		font-weight: 400;
		letter-spacing: normal;
	}
}
</style>

