// @ts-nocheck
import globalVariables from "@/global";
import { EComService } from "@connectngo/sdk";

const EcomReskinMixin = {
    computed : {
        ecomReskinFfEnabled(): boolean {
            const { feature_flags } = globalVariables.websiteConfig.data || {};
            return feature_flags?.includes('co-6205-ecom-reskin');
        },
    },
    methods : {
        redirectToProductV2UrlFromSlug(productSlug: string): void {
            if(this.$root.tags) {
                const productTag = this.$root.tags.results.find(tag => {
                    return tag.data.navigable &&
                        tag.data.products.find(product => product.data.slug === this.$route.params.slug)
                })

                if(!productTag) {
                    return this.$router.push({ name: "error404" });
                }

                this.$router.push({
                    name: 'tag',
                    params: { key: productTag.data.slug },
                    hash : '#' + productSlug
                })
            } else {
                // If access old URL directly and tags not loaded yet
                new EComService().getTags()
                    .then(tagsResponse => {
                        this.$root.tags = tagsResponse;
                        if(this.$root.tags) {
                            this.redirectToProductV2UrlFromSlug(productSlug);
                        }
                    })
                    .catch((reason) => this.$handleError(this, reason))
                    .finally(() => (this.loading = false));
            }
        }
    }
}

export default EcomReskinMixin;