// @ts-nocheck
import Vue from "vue";
import globalVariables from '@/global';
import {AddToCartProduct, EComService, EventBus, ProductModel} from '@connectngo/sdk'

const ProductV2Mixin =  Vue.extend({
	computed: {
		productWithEventGroup () {
			const productWithEvent = new Set();
			const eventProductIds = [];
			this.productsSelected.forEach(({ product }) => {
				// Product event
				if (product.data.event_group_id && !eventProductIds.includes(product.data.id)) {
					eventProductIds.push(product.data.id);
					productWithEvent.add(product);
				}

				// Modifiers events
				if (!product.data.is_combo && product.data.has_modifiers) {
					const originalProduct = this.productsSelected.get(product.data.id);

					originalProduct.modifiers.forEach(modifier => {
						if (modifier.modifier.event_group_id && !eventProductIds.includes(modifier.modifier.id)) {
							eventProductIds.push(modifier.modifier.id);
							productWithEvent.add(new ProductModel(modifier.modifier))
						}
					});
				}

				// Combo events
				if (product.data.is_combo) {
					product.data.combo_products.filter(combo => {
						if (combo.event_group_id) {
							if(combo.has_modifiers) {
								// Remove modifiers in combo -> Not supported
								combo.modifiers = [];
								combo.has_modifiers = false;
							}
							combo.parent_combo_product = product;
							combo.parent_combo_product_id = product.data.id;
							productWithEvent.add(new ProductModel(combo) )
						}
					})
				}
			});
			return Array.from(productWithEvent);
		},
		hasCalendar() {
			return this.productWithEventGroup.length > 0;
		},
		hasAllRequiredEvents() {
			return this.productWithEventGroup.length === 0 || Object.keys(this.selectedTimeSlots).length === this.productWithEventGroup.length
		},
		canAddToCart () {
			return !this.hasCalendar || (this.selectedDate !== null && this.hasAllRequiredEvents);
		}
	},
	methods: {
		formatArrayOfProduct() {
			return Array.from(this.productsSelected.entries()).map(([key, value]) => {
				//sending undefined as parentComboProductId, because dynamic pricing is not supported for combos for now...
				const productPrice = this.getProductDynamicPrice(value.product.data.id, value.quantity, "undefined") ?? (value.product.data.price * value.quantity).toFixed(2)

				const comboProducts = value.product.data.combo_products?.map(comboProduct => {
					return { ...comboProduct, quantity : this.getComboQuantity(value.product, comboProduct.id) }
				}) ?? []

				return {
					id : key,
					name : this.$options.filters.translatable(
						value.product.data.name,
						value.product.data.name_i18n,
						this.$i18n.locale
					),
					event_group_id: value.product.data.event_group_id,
					price : parseFloat(productPrice?.amount ?? productPrice),
					quantity : value.quantity,
					modifiers : value.modifiers ? value.modifiers : [],
					combo_products : comboProducts,
				}
			});
		},
		formatModifiers(modifiers) {
			const formattedModifiers = [];
			modifiers.forEach(value => {
				formattedModifiers.push({
					productId: value.modifier.id,
					quantity: value.quantity,
					eventId: this.getProductEventIdSelected(value.modifier.id, undefined),
					price: value.modifier.price,
				})
			});
			return formattedModifiers;
		},
		formatComboProducts(product) {
			const comboData = [];
			product.data.combo_products.forEach(comboProduct => {
				const productQty = this.getComboQuantity(product, comboProduct.id)
				comboData.push({
					productId: comboProduct.id,
					quantity: productQty,
					eventId: this.getProductEventIdSelected(comboProduct.id, product.data.id)
				})
			})
			return comboData;
		},
		getProductEventIdSelected(productId, parentComboProductId) {
			return this.selectedTimeSlots?.[`${productId}-${parentComboProductId}`]?.event_id ?? null
		},
		getComboQuantity(product, comboProductId) {
			return product.data.combo_quantities.find(quantity => quantity.product_id === comboProductId).quantity
		},
		updateModifierQuantity(product, modifier, quantity) {
			if (quantity > 0) {
				this.addModifierToProduct(product, modifier, quantity);
			} else {
				this.removeModifierFromProduct(product, modifier);
			}
		},
		addModifierToProduct(product, modifier, quantity) {
			if (this.productsSelected.has(product.data.id)) {
				const productData = this.productsSelected.get(product.data.id);

				if (!productData.modifiers) {
					productData.modifiers = new Map();
				}

				productData.modifiers.set(modifier.id, { modifier, quantity });

				this.productsSelected.set(product.data.id, productData);

				this.productsSelected = new Map(this.productsSelected);
			}
		},
		removeModifierFromProduct(product, modifier) {
			if (this.productsSelected.has(product.data.id)) {
				const productData = this.productsSelected.get(product.data.id);

				if (productData.modifiers && productData.modifiers.has(modifier.id)) {
					productData.modifiers.delete(modifier.id);
					this.productsSelected.set(product.data.id, productData);
				}this.productsSelected = new Map(this.productsSelected);
			}
		},
		addToCart(products = null) {
			this.addToCartLoading = true;

			// Check on params to prevent event sent as param from @click action
			if (!products || !(products instanceof Map) || products.size === 0) {
				products = this.productsSelected
			}

			const productsDataFormat = Array.from(products.entries()).map(([key, value]) => {
				const eventId = this.getProductEventIdSelected(value.product.data.id, value.product.data?.parent_combo_product_id);

				return new AddToCartProduct({
					productId : value.id,
					quantity : value.quantity,
					eventId :  eventId,
					modifiers : value.modifiers ? this.formatModifiers(value.modifiers, eventId) : [],
					comboItems : value.product.data.is_combo ? this.formatComboProducts(value.product): [],
				})
			});

			const hasAtLeastOneEventId = productsDataFormat.some(product => product.eventId !== null);

			return new EComService().addMultipleProductsToCart(productsDataFormat)
				.then(data => {
					globalVariables.cart = data;
					EventBus.publish("CART_UPDATED", data);
					if (hasAtLeastOneEventId) {
						this.closeModal();
					}
					if (this.isCrossSell) {
						this.$router.push({ name: "cart" });
					}
					this.$snack(this.$i18n.t("product.cart.added"));
					this.$root.$emit('addToCartSuccess', productsDataFormat)
				})
				.catch(err => this.$handleError(this, err))
				.finally(() => this.addToCartLoading = false)
		},

		updateProductSelectedQuantity(product, quantity) {
			if (quantity > 0) {
				if (this.productsSelected.has(product.data.id)) {

					const productData = this.productsSelected.get(product.data.id);
					productData.quantity = quantity;

					this.productsSelected.set(product.data.id, productData);
				} else {
					this.productsSelected.set(product.data.id, {
						id: product.data.id,
						product,
						quantity,
						modifiers: new Map()
					});
				}
			} else {
				this.productsSelected.delete(product.data.id);
			}

			this.noProduct = this.productsSelected.size === 0;

			this.productsSelected = new Map(this.productsSelected);
		},
	},
	watch: {}
});

export default ProductV2Mixin;
