import Vue from 'vue';
import moment from 'moment';
import globalVariables from "@/global";
import momentTZ from "moment-timezone";

Vue.filter('completeUserName', (user) => {
	return ((user.data.firstname ? user.data.firstname : '')
		+ ' '
		+ (user.data.lastname ? user.data.lastname : '')).trim() || user.data.gamename.trim();
});

Vue.filter('shortUserName', (user) => {
	if (user.data.salesgroup_id !== null) {
		return user.data.company_name;
	}
	const name = ((user.data.firstname + ' ' + (user.data.lastname || '').substring(0, 1)).trim() || user.data.gamename).trim();
	if (user.data.lastname) {
		return name + '.';
	}
	return name;
});

Vue.filter('timestampToHumanDate', (ts) => {
	return moment(ts).format('LL LTS');
});

Vue.filter('timestampToDateOnly', (ts) => {
	return moment(ts).format('LL');
});

Vue.filter('tsToFormat', (ts, format, useTenantsTimezone = false) => {
	const momentObj = moment(ts);

	if (useTenantsTimezone) {
		momentObj.tz(globalVariables.vm.$root.websiteConfig.data.tenant.timezone);
	}

	return momentObj.format(format);
});

Vue.filter('currency', (value, currency, locale = globalVariables.vm.$i18n.locale) => {
	let currencyValue = Intl.NumberFormat(locale, { style: 'currency', currency: currency || globalVariables.websiteConfig.data.tenant.currency.code }).format(value);

	if (globalVariables.websiteConfig.data.tenant.currency.code === 'QAR') {
		currencyValue = currencyValue.replace('QAR', '') + ' QAR';
	}

	return currencyValue;
});

Vue.filter('currencyWithoutCountry', (value, currency, locale = globalVariables.vm.$i18n.locale) => {
	return Intl.NumberFormat(locale, {
		style: 'currency',
		currency: currency || globalVariables.websiteConfig.data.tenant.currency.code,
		currencyDisplay: 'narrowSymbol'
	}).format(value);
});

Vue.filter('currencySign', (value, currency, locale = 'en-US') => {
	return Intl.NumberFormat(locale, {
		style: 'currency',
		currency: currency || globalVariables.websiteConfig.data.tenant.currency.code
	}).format(value).replaceAll(/[0-9,.]/gi, '');
});

Vue.filter('taxes', (value, locale = 'en-US') => {
	return Intl.NumberFormat(locale, {
		style: "percent",
		maximumFractionDigits: 5,
	}).format(value);
});

Vue.filter('translatable', (original, json, locale = globalVariables.vm.$i18n.locale) => {
	try {
		if (json !== null && typeof json === 'string') {
			const parsed: any = JSON.parse(json);
			// Override missing translation to fr if no fr-fr translation set
			if (locale === 'fr-fr' && !parsed['fr-fr'] && parsed.fr) {
				return parsed.fr;
			}
			return parsed[locale] || original;
		} else if (json !== null && json instanceof Object) {
			// Override missing translation to fr if no fr-fr translation set
			if (locale === 'fr-fr' && !json['fr-fr'] && json.fr) {
				return json.fr;
			}
			return json[locale] || original;
		}
		return original;
	} catch (error) {
		return original;
	}
});

Vue.filter('dateToTenantTimezone', (date = new Date()) => {
	const now = momentTZ(date);
	const serverOffset = now.utcOffset();
	now.tz(globalVariables.websiteConfig.data.tenant.timezone);

	const localOffset = now.utcOffset();
	const diffInMinutes = serverOffset - localOffset;
	const tenantDate = moment(date);
	tenantDate.add(-diffInMinutes, 'minutes');

	return tenantDate.clone();
})
