<template>
	<div>
		<p
			class="btn-back mb-4 systemDark1--text"
			style="cursor:pointer"
			:class="{ 'text-center modal-title': isSingleCombo }"
			@click="!isSingleCombo && $emit('showList')"
			v-if="showDetail"
		>
			<v-icon v-if="!isSingleCombo">
				mdi-arrow-left
			</v-icon>
			<span v-if="!isSingleCombo" v-text="$t('comboModal.back_to_available_combos')"></span>
		</p>
		<div class="combo-border combo-item-content" :class="{'d-flex flex-row pt-0': isDetailViewDisplayedOnDesktop }">
			<!-- Image -->
			<v-col :cols="isDetailViewDisplayedOnDesktop ? 6 : 12" class="image-container" v-if="showImage">
				<v-img
					:src="comboImage"
					:class="showDetail ? 'combo-image-details' : 'combo-image'"
					:style="{ maxWidth: isDetailViewDisplayedOnDesktop ? '100%' : '356px' }"
					cover
					aspect-ratio="2"

				/>
				<div class="promotion-label mediumGreen" v-if="combo.parent_product.data.promotion !== null">Promotion!</div>
			</v-col>
			<!-- Info section -->
			<v-col
				:cols="isDetailViewDisplayedOnDesktop ? 6 : 12"
				class='mt-1 py-6 px-6 d-flex flex-column'
				:class="{ 'px-6': isDesktopBreakpoint, 'pt-0': showImage && !isDesktopBreakpoint }"

			>
				<div class="d-flex flex-column combo-item-container">
				<div class="combo-title text-left systemDark1--text">
						<!-- Combo name -->
						{{$options.filters.translatable(combo.parent_product.data.name,combo.parent_product.data.name_i18n, $i18n.locale)}}
					</div>
					<div class="price-container d-flex align-center justify-space-between" v-if="comboPrice">
						<!-- Discount -->
						<span class="discount-percent secondaryLight systemDark1--text" v-if="discountPercent" v-text="$t('combo.discountPercent', { discount: discountPercent })" />
						<!-- Price -->
						<div class="price-container d-flex align-end justify-space-between" v-if="showDetail">
							<del class="original-price red--text" v-if="msrp && msrp > comboPrice">{{ $options.filters.currency(msrp) }}</del>
							<span class="actual-price secondaryDark--text" :class="{ 'bolder': isDesktopBreakpoint && !showDetail }">{{ $options.filters.currency(comboPrice) }}</span>
						</div>
						<template v-else>
							<del class="original-price red--text" v-if="msrp && msrp > comboPrice">{{ $options.filters.currency(msrp) }}</del>
							<span class="actual-price secondaryDark--text" :class="{ 'bolder': isDesktopBreakpoint && !showDetail }">{{ $options.filters.currency(comboPrice) }}</span>
						</template>
					</div>
					<div v-else>
						<p class="card-text mb-0 red--text"><strong>{{ $t('product.product_price_not_set') }}</strong></p>
					</div>
					<div
						v-if="productSummaries.length > 0"
						class="d-flex flex-column products-summary-container"

					>
						<h3 class="products-summary-title systemDark1--text">{{ thisIncludesTitle }}</h3>
						<ul>
							<li v-for="summary in productSummaries" :key="summary" class="mediumGrey--text">
								{{ summary }}
							</li>
						</ul>
					</div>
				</div>
			</v-col>
		</div>
		<!-- Calendar and timeslot picker -->
		<template v-if="showDetail">
			<v-row class="mt-6 mx-0" style="gap: 24px;" v-if="combo.related_combos?.some(combo => !!combo.data.product.data.event_group?.id)">
				<v-col :cols="isDesktopBreakpoint ? 6 : 12" class="d-flex flex-column flex-grow-1 justify-center align-center px-0 pb-0 calendar-container combo-border">
					<Calendar
						ref="calendar"
						:hide-time-slots="false"
						:hide-title="true"
						class="mb-0 px-0"
						:combo="combo"
						v-model="selectedEventID"
						:unavailableSlots="unavailableSlots"
						@daySelect="handleDaySelect"
						@update:current-slots="handleUpdateCurrentSlots"
						:showDatePicker="true"
					>
					</Calendar>
				</v-col>
				<v-col :cols="!isDesktopBreakpoint && 12" class="timepicker-container">
					<product-timeslot-picker
						v-for="(product, index) in timebasedProducts"
						:key="product.data.id"
						className="timepicker"
						:ref="`productTimeslotPicker-${index}`"
						:product="product"
						product-type="tag"
						:skeleton="skeleton"
						:event-id.sync="selectedEventID"
						:showTitle="false"
						:time-slots="timeSlots.filter(timeSlot => timeSlot.productId === product?.data?.id)"
						:progress="`${index + 1}/${timebasedProducts.length}`"
						@option-selected="(ts) => handleSelectTimeslot(ts, product.data.id, index)"
						:otherSelectedTimeslots="otherSelectedTimeslots[product.data.id]"
					/>
				</v-col>
			</v-row>
		</template>
	</div>
</template>
<script>
	import { EComService, EventBus, ComboModel } from "@connectngo/sdk";
	import globalVariables from "@/global";
	import Calendar from "@/components/CalendarCombo.vue";
	import BackgroundImage from "@/assets/images/background.svg";
	import ProductTimeslotPicker from "@/components/ProductTimeslotPicker";
	import Vue from 'vue';

	export default {
		name: "ComboItemExperimental",
		components: { Calendar, ProductTimeslotPicker },
		props: {
			combo: {
				type: ComboModel,
				required: true,
			},
			showDetail: {
				type: Boolean,
				default: false,
			},
			cancelModal: {
				type: Function,
				default: () => {},
			},
			isSingleCombo: {
				type: Boolean,
				default: false,
			},
			beforeOpen: {
				type: Boolean,
				default: false,
			}
		},
		emits: ["showList", "replace-disabled"],
		data() {
			return {
				selectedEventID: null,
				errorLoadingImage: {},
				selectedDay: null,
				skeleton: false,
				loading: false,
				unavailableSlots: [],
				timeSlots: [],
				selectedTimeslots: {},
				otherSelectedTimeslots: {},
			};
		},
		methods: {
			/* Google Tag Manager */
			triggerGAAddEvent(quantity) {
				if (this.$gtm) {
					dataLayer.push({ ecommerce: null }); // Clear the previous ecommerce object.
					dataLayer.push({
						event: "add_to_cart",
						ecommerce: {
							items: [
								{
									item_id: this.combo.parent_product.data.id,
									item_name: this.$options.filters.translatable(
										this.combo.parent_product.data.name,
										this.combo.parent_product.data.name_i18n,
										this.$i18n.locale
									),
									currency:
										this.$root.websiteConfig.data.tenant.currency
											.code,
									index: 1,
									price: this.combo.parent_product.data.price,
									quantity: quantity,
								},
							],
						},
					});
				}
			},
			getOtherSelectedTimeslots(productId) {
				const timeslots = Object.keys(this.selectedTimeslots)
      				.filter(id => Number(id) !== Number(productId) && this.selectedTimeslots[id])
      				.map(id => this.selectedTimeslots[id]);
				return timeslots;
			},
			handleSelectTimeslot(selectedTime, productId, index) {
				if (!selectedTime) {
					this.$delete(this.selectedTimeslots, productId);
				} else {
					this.$set(this.selectedTimeslots, productId, { ...selectedTime, index });
				}
			},
			getProductImage(product) {
				const hasError = this.errorLoadingImage[product.id];
				return hasError || !product?.data?.image ? BackgroundImage : product?.data?.image;
			},
			handleImageError(product) {
				this.$set(this.errorLoadingImage, product.id, true);
				this.productImage = BackgroundImage;
			},
			applyClicked() {
				if (!this.hasPrice) {
					return;
				}
				this.loading = true;

				const qty =
					this.getSalesGroupRequirement("min_quantity") ??
					this.getSalesGroupRequirement("increment") ??
					1;

				const data = {
					id: this.combo.id,
					productId: this.combo.parent_product.data.id,
					quantity: qty,
					eventId: this.combo.parent_product.data.event_group?.id,
					price: this.combo.parent_product.data.price,
					comboItems: this.combo.related_combos?.map(combo => {
						const selectedTimeslot = this.selectedTimeslots[combo.data?.product?.data?.id];

						const eventId = selectedTimeslot?.id ?? null;

						return {
							productId: combo.data.product.data.id,
							quantity: combo.data.quantity,
							eventId,
						}
					})
				};
				this.applyComboRequest(data);
			},
			applyComboRequest(data) {
				const items = {
					productId: data.productId,
					quantity: data.quantity,
					comboItems: data.comboItems
				}

				const isTimeBasedCombo = this.combo.related_combos?.some(combo => !!combo.data.product.data.event_group?.id);

				const eComService = new EComService();

				const cartPromise = isTimeBasedCombo
					? eComService.addTimeBasedComboToCart(items)
					: eComService.addComboToCart({
						productId: data.productId,
						quantity: data.quantity,
					});

				return cartPromise
					.then((model) => {
						this.formErrors = {};
						this.$snack(this.$i18n.t("product.cart.added"));
						this.updateCart(model);
						this.triggerGAAddEvent(data.quantity);
						this.getAvailableCombos();
					})
					.catch((error) => this.$handleError(this, error))
					.finally(() => (this.loading = false));
			},
			getAvailableCombos() {
				EventBus.publish("COMBO_UPDATED", []);
				return new EComService()
					.getAvailableCombos()
					.then((combos) => {
						EventBus.publish("COMBO_UPDATED", combos.results);
					})
					.catch((reason) => {
						if (reason.response && reason.response.status !== 404) {
							Vue.prototype.$snack(reason.messages.error, "error");
						}
						EventBus.publish("COMBO_UPDATED", []);
					});
			},
			updateCart(model) {
				globalVariables.cart = model;
				EventBus.publish("CART_UPDATED", model);
			},
			getSalesGroupRequirements() {
				if (
					this.combo == null ||
					typeof this.combo.parent_product === "undefined"
				) {
					return null;
				}

				const parentProduct = this.combo.parent_product;

				if (
					typeof parentProduct.salesgroups === "undefined" ||
					parentProduct.salesgroups.length === 0
				) {
					return null;
				}

				return this.combo.parent_product.data.salesgroups[0].pivot;
			},
			getSalesGroupRequirement(property) {
				const salesGroupRequirement = this.getSalesGroupRequirements();

				if (salesGroupRequirement === null) {
					return null;
				}

				if (!salesGroupRequirement.hasOwnProperty(property)) {
					return "";
				}

				return salesGroupRequirement[property] ?? null;
			},
			getSalesGroupRequirementString(property, label, validate) {
				const value = this.getSalesGroupRequirement(property);

				if (!validate(value)) {
					return "";
				}

				return `${label}: ${value}`;
			},
			handleDaySelect(selectedDay) {
				this.selectedDay = selectedDay;
				this.scrollToTimeslotPicker();
			},
			scrollToTimeslotPicker() {
				this.$nextTick(() => {
					const refKeys = Object.keys(this.$refs).filter(key => key.includes('productTimeslotPicker-'));
					const lastRefName = refKeys[refKeys.length - 1];
					const element = this.$refs[lastRefName];
					if (element && element.length > 0) {
						const domElement = element[0].$el || element[0];
						domElement?.scrollIntoView({
							behavior: "smooth",
							block: "start",
						});
					}
				})
			},
			getTag(relatedCombo) {
				if (
					relatedCombo.product &&
					relatedCombo.product.data.tags &&
					relatedCombo.product.data.tags.length > 0
				) {
					return this.$options.filters.translatable(
						relatedCombo.product.data.tags[0].name,
						relatedCombo.product.data.tags[0].name_i18n,
						this.$i18n.locale
					);
				}
				return "Combo";
			},
			pickATimeMobileTitle(product, index) {
				return product.data.name + ` ${index}/${this.products.length}`;
			},
			handleUpdateCurrentSlots(slots) {
				this.timeSlots = slots
			},
			isTimebasedProduct(product) {
				return product.data.id && product.data.event_group?.id;
			},
		},
		computed: {
			isDetailViewDisplayedOnDesktop() {
				return this.showDetail && this.isDesktopBreakpoint;
			},
			timebasedProducts() {
				return this.products.filter(this.isTimebasedProduct);
			},
			productTimeslotPickerKey() {
				return (productId) => {
					const selectedTimeslot = this.selectedTimeslots[productId];
        			return selectedTimeslot ? `${productId}-${JSON.stringify(selectedTimeslot)}` : productId;
				};
			},
			thisIncludesTitle() {
				return this.showDetail ? this.$t('comboModal.this_includes') + ':' : this.$t('comboModal.this_includes')
			},
			showImage() {
				return this.combo.parent_product.data.image !== null && !this.showDetail || this.isDesktopBreakpoint;
			},
			comboImage() {
				return this.combo.parent_product.data.image || BackgroundImage;
			},
			showCTA() {
				return this.isDesktopBreakpoint || (!this.isDesktopBreakpoint && this.showDetail);
			},
			eventGroupIds() {
				return this.combo?.related_combos.map(combo => combo.data.product.data.event_group.id);
			},
			allTimeslotsSelected() {
    			const productsWithEventGroupId = this.products.filter(product => product.data.event_group?.id);
    			return this.selectedTimeslots && Object.values(this.selectedTimeslots).filter(item => item && item.id).length === productsWithEventGroupId.length;
  			},
			products() {
				return this.combo?.related_combos.map(combo => combo.data.product);
			},
			productSummaries() {
				const related_combos = this.combo?.related_combos;
				if (!related_combos) {
					return [];
				}
				const summaries = related_combos.map((combo) => {
					const quantity = combo?.data?.quantity;
					const productName = combo?.data?.product?.data?.name;
					return `${quantity}x ${productName}`;
				});
				return summaries;
			},
			relatedProducts() {
				return this.combo.related_combos.map((combo) => combo.data.product);
			},
			comboPrice() {
				return this.combo.parent_product.data?.price;
			},
			msrp() {
				return this.combo.parent_product.data?.msrp ?? 0;
			},
			discountPercent() {
				if (this.msrp && this.comboPrice && this.msrp > this.comboPrice) {
					return this.comboPrice
						? Math.round(
							((this.msrp - this.comboPrice) / this.msrp) * 100
						)
						: 0;
				}
				return 0;
			},
			hasPrice() {
				return (
					this.combo.parent_product.data !== null &&
					this.combo.parent_product.data.price !== null &&
					this.combo.parent_product.data.price.is_variable !== null &&
					this.combo.parent_product.data.price.min_price !== null
				);
			},
			minQty() {
				return this.getSalesGroupRequirementString(
					"min_quantity",
					this.$t("min_quantity"),
					(v) => v > 0
				);
			},
			maxQty() {
				return this.getSalesGroupRequirementString(
					"max_quantity",
					this.$t("max_quantity"),
					(v) => v > 0
				);
			},
			increment() {
				return this.getSalesGroupRequirementString(
					"increment",
					this.$t("increment"),
					(v) => v > 0
				);
			},
			incrementValue() {
				if (this.combo.increment !== null) {
					return this.combo.increment;
				}
				return 1;
			},
			isDesktopBreakpoint() {
				return (
					this.$vuetify.breakpoint.lg ||
					this.$vuetify.breakpoint.xl ||
					this.$vuetify.breakpoint.md
				);
			},
			_eventGroupIds() {
				const arr = this.combo.related_combos
					.filter(
						(relatedCombo) =>
							relatedCombo.data.product &&
							relatedCombo.data.product.data.event_group_id &&
							relatedCombo.data.product.data.event_group_id !==
								"no_event"
					)
					.map(
						(relatedCombo) =>
							relatedCombo.data.product.data.event_group.id
					)
					.flat();

				return arr.filter((item, index) => arr.indexOf(item) === index);
			},
			canExchange() {
				if (this._eventGroupIds.length !== 0) {
					return !!this.selectedDay;
				}
				return true;
			},
			replaceDisabled() {
				return !this.canExchange ||
					!this.allTimeslotsSelected ||
					!this.hasPrice;
			}
		},
		created() {
			for (const product of this.products) {
				if (product?.data?.id) {
					this.$set(this.otherSelectedTimeslots, product.data.id, []);
				}
			}
		},
		watch: {
			selectedDay: {
				deep: true,
				handler(newVal, oldVal) {
					if (newVal !== oldVal) {
						for (const product of this.products) {
							if (product?.data?.id) {
								this.$set(this.selectedTimeslots, product.data.id, null);
								this.$set(this.otherSelectedTimeslots, product.data.id, []);
							}
						}
					}
				},
			},
			selectedTimeslots: {
				deep: true,
				handler() {
					for (const product of this.products) {
						if (product?.data?.id) {
							this.$set(
								this.otherSelectedTimeslots,
								product.data.id,
								this.getOtherSelectedTimeslots(product.data.id)
							);
						}
					}
				},
			},
			replaceDisabled: {
				immediate: true,
				handler(newVal) {
					this.$emit("replace-disabled", newVal);
				}
			},
			loading(value) {
				this.$emit('loading', value);
			},
			beforeOpen: {
				immediate: true,
				handler(newVal) {
					if (newVal) {
						this.loading = false;
						this.timeSlots = [];
					}
				}
			}
		},
	};
</script>

<style scoped>
	.card {
		background: rgb(248, 249, 250);
	}

	.card:hover {
		opacity: 1 !important;
	}

	table img {
		width: 64px;
		height: 64px;
		object-fit: cover;
		border-radius: 50%;
	}

	table tr td:first-child {
		width: 64px;
	}

	table tr td {
		padding: 0;
		padding-top: 10px;
	}

	table tr {
		padding-top: 10px;
	}
</style>

<style lang="scss">

	#combo-modal-experimental {
		.promotion-label {
			position: absolute;
			padding: 5px;
			top: 1%;
			left: 1%;
		}

		.modal-title {
			font-size: 22px !important;
			font-weight: 600 !important;
			line-height: 28px !important;
			text-align: center !important;
		}

		.calendar-container {
			height: fit-content;
		}

		.combo-border {
			border-radius: 16px;
			box-shadow: 0px 4px 8px 0px #6864641A;
			border: 1px solid #E3E3E3;
			flex-direction: column;
			border-radius: 16px;
			border: 1px;
			border: 1px solid #F2F2F2;
			min-height: 248px;

			.combo-image {
				border-top-left-radius: 16px;
				border-top-right-radius: 16px;
				min-height: 100%;
				min-width: 100%;
			}

			.combo-image-details {
				border-top-left-radius: 16px;
				border-bottom-left-radius: 16px;
				min-height: 100%;
				min-width: 100%;
			}

			.combo-item-container {
				display: flex;
				flex-direction: row;
				height: 100%;
				justify-content: space-between;
			}

			.price-container {
				display: flex;
				align-items: center;
				justify-content: center;
				gap: 16px;
			}
		}

		.timepicker-container {
			display: flex;
			flex-direction: column;
			gap: 16px;
			justify-content: center;
			padding: 0px;
		}

		.combo-item-content {
			min-width: 357px;
			cursor: pointer;
		}

		.combo-truncate-text {
			-webkit-box-orient: vertical;
			line-height: 25px;
			overflow: hidden;
			text-overflow: ellipsis;
			display: -webkit-box;
		}

		.combo-title {
			-webkit-line-clamp: 1;
			padding-left: 0px;
			line-clamp: 1;
			font-size: 27px;
			font-weight: 600;
			line-height: 35px;
			text-align: left;
		}

		.row {
			gap: 24px;
		}

		.combo-description {
			height: 100px;
			-webkit-line-clamp: 4;
			line-clamp: 4;
		}

		.v-item--active .combo-description {
			height: auto !important;
			-webkit-line-clamp: unset !important;
			line-clamp: unset !important;
		}

		.image-container {
			position: relative;
			padding: 0px;
			.promotion-label {
				position: absolute;
				top: 8px;
				left: 7.93px;
				padding: 8px 16px 8px 16px;
				border-radius: 16px;
				gap: 8px;
				background-color: #E0F5F7;
				font-size: 14px;
				font-weight: 400;
				line-height: 21px;
				text-align: left;
			}
		}

		.discount-percent {
			padding: 8px 16px 8px 16px;
			border-radius: 16px;
			font-size: 14px;
			font-weight: 400;
			line-height: 17px;
			text-align: left;
			white-space: nowrap;
		}

		.original-price {
			font-size: 18px;
			font-weight: 600;
			line-height: 23px;
			text-align: left;
			text-decoration: line-through;
		}

		.actual-price {
			font-size: 22px;
			font-weight: 600;
			line-height: 28px;
			text-align: left;
			&.bolder {
				font-size: 27px;
				font-weight: 600;
				line-height: 36px;
				text-align: left;
			}
		}

		.products-summary-container {
			> div {
				display: flex;
				flex-direction: column;
				gap: 16px;
			}
			ul, h3 {
				font-size: 14px;
				font-weight: 600;
				list-style: none;
				line-height: 21px;
				text-align: left;
				padding-left: 0;
				li {
					font-weight: 400;
					line-height: 21px;
					letter-spacing: 0.16px;
				}
			}
			h3 {
				font-weight: bolder;
			}
		}

		.product-card {
			display: flex;
			flex-direction: row;
			.v-image {
				border-radius: 16px
			}
			.VueCarousel-slide {
				padding: 0px;
			}
		}

		.row {
			margin-bottom: 0px;
			margin-top: 0px;
		}

		.combo-item-container {
			gap: 24px;
		}

		@media screen and (max-width: 600px) {
			.combo-title {
				font-size: 18px !important;
				font-weight: 600 !important;
				line-height: 23px;
				text-align: left;
			}

			.original-price {
				font-size: 11px;
				font-weight: 400;
				line-height: 17px;
				text-align: left;
			}

			.row {
				gap: 0px;
			}

			> h3 {
				padding-left: 24px;
			}

			.actual-price {
				font-size: 14px;
				font-weight: 600;
				line-height: 21px;
				text-align: left;
			}

			.combo-item-content {
				display: flex;
				flex-direction: column;
				gap: 24px;
				min-width: auto;
			}

			.original-price {
				font-size: 11px;
				font-weight: 400;
				line-height: 17px;
				text-align: left;
			}

			.combo-border {
				box-shadow: 0px 4px 8px 0px #6864641A;
			}
		}
	}


</style>
