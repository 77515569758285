<template>
	<div>
		<v-dialog v-model="modalOpen" width="800" @click:outside="closeModal" class="custom-modal">
			<h3>{{ $t('product.crossSells.title') }}</h3>
			<p class="text-subtitle-1 mt-3">{{ $t('product.crossSells.subTitle') }}</p>

			<ProductCard
				class="mt-6"
				v-for="(product, index) in crossSellProducts"
				:key="index"
				:product="product"
				:is-in-modal="false"
				:productsSelected="productsSelected"
				@quantityChanged="updateQuantity"
				@quantityModifierChanged="updateModifierQuantity"/>

			<div class="d-flex justify-end pt-4">
				<v-btn class="cancel-button mr-3" outlined @click="goToCart">
					{{ $t('product.crossSells.backButton') }}
				</v-btn>
				<v-btn
					outlined
					class="add-to-cart-button"
					:disabled="!canAddToCart"
					@click="goNext">
					{{ nextLabel }}
				</v-btn>
			</div>
		</v-dialog>
		<ProductPackageSelector
			v-if="canAddToCart"
			ref="ProductPackageSelector"
			:isCrossSell="true"
			:productsSelected="productsSelected"
			@addToCartSuccess="handleAddToCart"/>
	</div>
</template>

<script>
	import ProductCard from '@/components/ProductV2/ProductCard';
	import ProductV2Mixin from '@/mixins/ProductV2Mixin';
	import ProductPackageSelector from '@/components/ProductV2/ProductPackageSelector';

	export default {
		name: "CrossSellsModal",
		mixins: [ProductV2Mixin],
		components: {
			ProductCard,
			ProductPackageSelector
		},
		props: {
			isOpen: {
				type: Boolean,
				required: false,
			},
			crossSellProducts: {
				type: Array,
				default: [],
			},
		},
		data() {
			return {
				modalOpen: this.isOpen,
				productsSelected: new Map(),
				products: []
			};
		},
		watch: {
			isOpen(newVal) {
				this.modalOpen = newVal;
			},
		},
		computed: {
			canAddToCart() {
				return this.productsSelected.size > 0
			},
			nextLabel() {
				if (this.canAddToCart && this.hasCalendar) {
					return this.$t('tag.chooseVisitingDate');
				}
				return this.$t('btn.proceedCheckout');
			}
		},
		methods: {
			handleAddToCart() {
				this.productsSelected = new Map();
			},
			updateQuantity(product, quantity) {
				if (quantity > 0) {
					this.productsSelected.set(product.data.id, {id: product.data.id, product, quantity });
				} else {
					this.productsSelected.delete(product.data.id);
				}
				this.productsSelected = new Map(this.productsSelected);
			},
			closeModal() {
				this.modalOpen = false;
				this.$emit("close");
			},
			goToCart() {
				this.$router.push({ name: "cart" });
			},
			goNext() {
				this.hasCalendar
					? this.$refs.ProductPackageSelector.openModal()
					: this.addToCart();
				this.closeModal();
			},
		},
	};
</script>
<style lang="scss" scoped>
	h3 {
		color: $blackish;
	}
	.text-subtitle-1 {
		color: $grey-dark;
	}
	.add-to-cart-button {
		border: 1px solid $blackish;
		background-color: $blackish;
		color: white;
	}
	.add-to-cart-button.v-btn--disabled {
		border: 1px solid $grey-light;
		background-color: $grey-light;
	}
	::v-deep {
		.v-dialog.v-dialog--active {
			border-radius: 15px;
			background-color:$grey-lighter !important;
			padding: 40px;
		}
	}
</style>
