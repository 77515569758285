<template>
	<div class="mt-3 combo-detail">
		<hr class="mb-3">
		<p class="text-blackish">{{$t('product.combo.title')}}</p>
		<v-card
			v-for="(combo, index) in combos"
			:key="index"
			class="combo-card rounded-lg d-flex justify-space-between align-center pa-3 mt-4 " flat>
			<div>{{quantity(combo)}}x {{$options.filters.translatable(combo.name, combo.name_i18n, $i18n.locale)}}</div>
		</v-card>
	</div>
</template>

<script>

import {ProductModel } from '@connectngo/sdk'
export default {
	name: "ProductCombos",
	props : {
		product : {
			type : ProductModel,
			required: true,
		}
	},
	data() {
		return {
			combos : this.product.data.combo_products,
			quantities : this.product.data.combo_quantities,
		}
	},
	methods : {
		quantity(combo) {
			return this.quantities.find(quantity => quantity.product_id === combo.id).quantity
		}
	}
}
</script>

<style scoped lang="scss">
	 hr {
		 border-top : 1px dotted #000000;
	 }

	 .combo-card {
		 width: 100%;
		 border: 1px solid $light-grey;
	 }
	 .text-blackish {
		 color: $blackish
	 }
	 .combo-detail {
		 font-weight: 600;
	 }
</style>
