// @ts-nocheck

import { Component, Vue } from 'vue-property-decorator';
import { EComService, verifyCaptchaResult, Server } from '@connectngo/sdk';
import globalVariables from "@/global";

@Component
export default class CaptchaMixin extends Vue {
	get enableCaptcha(): boolean {
		return this.$root.websiteConfig.data.fields.gcp_captcha === "1"
			&& !!this.$root.websiteConfig.data.gcp_recaptcha
			&& globalVariables.websiteConfig.featureFlagEnabled("co-6612-ecom-captcha");
	}

	loadCaptcha() {
		if (!this.enableCaptcha) {
			console.info("Cannot enable captcha due to missing 'gcp_captcha' website config, 'co-612-ecom-captcha' feature flag, or 'sitekey' env var");

			return;
		}

		document.querySelectorAll(
			'script[src="https://www.google.com/recaptcha/enterprise.js"]'
		).forEach(script => script.remove());


		window.onReCaptchaSuccess = (token) => {
			this.onReCaptchaSuccess(token);
		};

		const script = document.createElement('script');
		script.src = 'https://www.google.com/recaptcha/enterprise.js';
		script.async = true;
		script.defer = true;
		document.head.appendChild(script);
	}

	onReCaptchaSuccess(token) {
		Server.setCaptcha(token, 'PAYMENT');
	}

	verifyCaptcha(token) {
		new EComService().verifyCaptchaResult(
			token,
			'PAYMENT'
		).then(response => {
			this.isCaptchaVerified = response.httpStatus === 200
		})(error => this.$handleError(this, error))
	}

	beforeDestroy(): void {
		window.onReCaptchaSuccess = null;
	}

	created(): void {
		this.loadCaptcha();
	}
}
