// @ts-nocheck
const WindowWidthMixin = {
	data: () => ({
		isMobile: false,
		isIpad: false,
		windowWidth: String,
		windowHeight: String,
	}),

	beforeDestroy() {
		if (typeof window === "undefined") return;
		window.removeEventListener("resize", this.onResize, { passive: true });
	},

	mounted() {
		this.onResize();
		window.addEventListener("resize", this.onResize, { passive: true });
	},

	methods: {
		onResize() {
			this.isIpad = window.innerWidth <= 1024;
			this.isMobile = window.innerWidth < 600;
			this.windowWidth = window.innerWidth;
			this.windowHeight = window.innerHeight;
		},
	},
};

export default WindowWidthMixin;
