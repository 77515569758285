<template>
	<v-card :class="['pa-5 card-container', 'rounded-lg', { 'no-border': isInModal }]" flat>
		<v-row :no-gutters="isInModal">
			<v-col cols="12"
				:md="isInModal ? '12' : '5'"
				class="d-flex align-self-stretch">
				<v-img
				:src="productImg"
				alt="Image"
				:aspect-ratio="16/9"
				class="rounded-lg"
				></v-img>
			</v-col>
			<!-- Modal only title-->
			<v-card-title
				class="text-left pa-0 mt-6 mb-4 text-blackish text-h6"
				v-if="isInModal">
				{{ productTitle }}
			</v-card-title>
			<v-col cols="12"
					v-if="isInModal && hasDescription()"
					md="12"
					class="d-flex align-self-stretch">
				<p v-html="getDescription()"></p>
			</v-col>
			<v-col cols="12"
					:md="isInModal ? 12 : 7"
					:class="['d-flex flex-column pa-3', isInModal ? 'modal-subcard' : '']">

					<h2 :class="['text-blackish text-h6', isInModal ? 'ml-1': '']">
						{{ productTitle }}
					</h2>
				<v-row v-if="isInModal" class="pa-3 d-flex align-start mt-1">
					<v-col :cols="isMobile ? 12 : 6" >
						<QuantityPicker
							:current-quantity="currentQuantity"
							:min-quantity="product.data.min_quantity"
							:max-quantity="product.data.max_quantity"
							:incrementValue="product.data.increment"
							@quantityChanged="emit"/>
					</v-col>
					<v-col :cols="isMobile ? 12 : 6" :class="['d-flex', 'align-center', isInModal && isMobile ? 'justify-space-between pa-2 mt-1 pb-0' : 'justify-end pt-1']">
						<!-- "Starting from:" text on the left -->
						<div v-if="product.data.price_show_lowest" class="text-caption text-grey-dark opacity-50 pr-2">
							{{ $t('product.starting_from') }}:
						</div>

						<!-- A column with MSRP above, and main price below -->
						<div class="d-flex flex-column align-end md-align-start">
							<div v-if="product.data.msrp > displayedPrice"
								class="text-caption text-grey-dark opacity-50 text-decoration-line-through">
								{{ $options.filters.currencyWithoutCountry(product.data.msrp) }}
							</div>

							<div class="text-h6 text-grey-dark">
								{{ $options.filters.currencyWithoutCountry(displayedPrice) }}
							</div>
						</div>
					</v-col>
				</v-row>

				<v-card-text
					v-if="truncatedDescription && !isInModal"
					class="text-left description-text pa-0 ma-0" v-html="truncatedDescription">
				</v-card-text>

				<QuantityPicker
					v-if="!isInModal"
					:current-quantity="currentQuantity"
					:min-quantity="product.data.min_quantity"
					:max-quantity="product.data.max_quantity"
					:incrementValue="product.data.increment"
					align="right"
					:class="['mt-auto quantity-picker', isMobile ? 'pa-2' : '']"
					@quantityChanged="emit"/>

				<ProductCombos v-if="isCombo && isInModal" :product="product" />
			</v-col>
		</v-row>
		<!-- Button and Price section -->
		<v-row class="mt-6" v-if="!isInModal">
			<v-col cols="12" md="5" v-if="!isMobile">
			</v-col>
			<v-col cols="12" md="7">
				<v-row class="" :class="{'justify-space-between' : !softCrossSell, 'justify-end' : softCrossSell}">
					<v-col
						v-if="!softCrossSell && !isIpad"
						cols="12"
						md="auto"
						max-md="6"
						class="">
						<v-btn
							@click="openModal"
							outlined
							class="elevation-0 text-caption border-sm rounded-md open-modal-button">
							{{ $t('product.find_out_more') }}
						</v-btn>
					</v-col>
					<v-col cols="12" md="auto" max-md="6" class="d-flex align-end justify-end md-justify-start">
						<!-- "Starting from:" text on the left -->
						<div v-if="product.data.price_show_lowest" class="text-caption text-grey-dark opacity-50 pr-2 pb-1">
							{{ $t('product.starting_from') }}:
						</div>

						<!-- A column with MSRP above, and main price below -->
						<div class="d-flex flex-column align-end md-align-start">
							<div v-if="product.data.msrp > displayedPrice"
								class="text-caption text-grey-dark opacity-50 text-decoration-line-through">
								{{ $options.filters.currencyWithoutCountry(product.data.msrp) }}
							</div>

							<div class="text-h6 text-grey-dark">
								{{ $options.filters.currencyWithoutCountry(displayedPrice) }}
							</div>
						</div>
					</v-col>
					<v-col
						v-if="!softCrossSell && isIpad"
						cols="12"
						md="auto"
						class="d-flex justify-center ml-auto open-modal-button-container">
						<v-btn
							@click="openModal"
							outlined
							class="elevation-0 text-caption border-sm rounded-md w-100 open-modal-button">
							{{ $t('product.find_out_more') }}
						</v-btn>
					</v-col>
				</v-row>
			</v-col>
		</v-row>

		<!-- Modifiers -->
		<v-skeleton-loader v-if="loadingModifiers" type="list-item" />

		<ProductModifiers
			v-for="(modifier, index) in requiredModifiers"
			:key="'required-modifier-' + index"
			v-if="modifier.price_lowest_available"
			:modifier="modifier"
			:requiredModifiers="true"
			:productsSelected="productsSelected"
			:product="product"
			@quantityModifierChanged="quantityModifierChanged"/>


		<template v-if="!softCrossSell">
			<hr class="mt-6" v-if="requiredModifiers.length > 0"/>
			<p class="text-blackish text-bold mt-10 ml-1 mb-9" v-if="nonRequiredModifiers.length > 0">{{ $t('product.modifier.title') }}</p>

			<ProductModifiers
				v-for="(modifier, index) in nonRequiredModifiers"
				:key="'non-required-modifier-' + index"
				v-if="modifier.price_lowest_available"
				:modifier="modifier"
				:requiredModifiers="false"
				:productsSelected="productsSelected"
				:product="product"
				@quantityModifierChanged="quantityModifierChanged"/>
		</template>

		<!-- COMBOS-->
		<ProductCombos v-if="isCombo && !isInModal" :product="product" />

		<!-- SOFT CROSS SELL ACTIONS-->
		<v-row v-if="softCrossSell" class="pt-2 pb-1">
			<v-col class="text-end pa-0 pr-1">
				<v-btn
					v-if="!product.data.event_group_id"
					elevation="0"
					class="add-to-cart-button"
					:disabled="currentQuantity === 0"
					:loading="addToCartLoading"
					@click="handleAddToCart(product)">
					{{ $t('btn.addToCart') }}
				</v-btn>
				<v-btn
					v-else
					elevation="0"
					class="add-to-cart-button"
					:disabled="currentQuantity === 0"
					@click="$emit('crossSellShowCalendar', product)">
					{{ $t('tag.chooseVisitingDate') }}
				</v-btn>
			</v-col>
		</v-row>
	</v-card>
</template>

<script>
	import BackgroundImage from '@/assets/images/background.svg'
	import { ProductModel, EComService } from '@connectngo/sdk'
	import Text from '@/utils/Text'
	import QuantityPicker from '@/components/Shared/QuantityPicker'
	import ProductModifiers from '@/components/ProductV2/ProductModifiers'
	import { WindowWidthMixin } from '@/mixins/ProductMixin'
	import ProductCombos from '@/components/ProductV2/ProductCombos.vue'
	import ProductV2Mixin from '@/mixins/ProductV2Mixin'

	export default {
		name: "ProductCard",
		components: { ProductCombos, QuantityPicker, ProductModifiers },
		mixins : [WindowWidthMixin, ProductV2Mixin],
		props: {
			product: {
				type: ProductModel,
				required: true
			},
			productsSelected: {
				type: Map,
				required: true
			},
			isInModal: {
				type: Boolean,
				default: false
			},
			softCrossSell: {
				type: Boolean,
				default: false
			},
		},
		data () {
			return {
				loadingModifiers: true,
				modifiers: [],
				productQuantity: 0,
				addToCartLoading : false,
			}
		},
		mounted() {
			this.$root.$on('addToCartSuccess', this.handleAddToCartSuccess)
			if(!this.product.data.is_combo) {
				this.getModifiers();
			} else {
				this.loadingModifiers = false
			}
		},
		beforeDestroy () {
			this.$root.$off('addToCartSuccess', this.handleAddToCartSuccess)
		},
		computed: {
			requiredModifiers() {
				return this.modifiers.filter(modifier => modifier.required === true);
			},
			nonRequiredModifiers() {
				return this.modifiers.filter(modifier => modifier.required === false);
			},
			displayedPrice()
			{
				if (this.product.data.price_show_lowest) {
					return this.product.data.price_lowest_available?.lowest_available ?? 0;
				}

				return this.product.data.price ?? 0;
			},
			isTimeBasedCombo() {
				return this.product.data.is_combo && this.product.data.combo_products.some(combo => combo.event_group_id)
			},
			currentQuantity() {
				if (this.productsSelected.size > 0) {
					return this.productsSelected.get(this.product.data.id)?.quantity || 0;
				}
				return 0
			},
			productTitle() {
				return this.$options.filters.translatable(this.product.data.name, this.product.data.name_i18n, this.$i18n.locale)
			},
			truncatedDescription() {
				const maxLength = this.isIPad ? 60 : 80;
				const description = this.getDescription();
				if (!description) {
					return null;
				}

				return description.length > maxLength ?
					Text.trimNotWithinWords( description, maxLength) + "..." : description;
			},
			productImg() {
				return this.product.data.image || BackgroundImage;
			},
			isCombo() {
				return this.product.data.is_combo && this.product.data.combo_products && this.product.data.combo_products.length > 0;
			}
		},
		methods: {
			getModifiers() {
				new EComService().getProductModifiers(this.product.data.id)
				.then(response => {
					response.data.event_groups.forEach((item, index) => {
						item.data.modifiers.forEach((modifier) => {
							if (modifier.data) {
								this.modifiers.push({ ...modifier.data, event_group_id :  item.data?.event_group_id});
							}
						});
					});
				})
				.catch((error) => this.$handleError(this, error))
				.finally(() => (this.loadingModifiers = false));
			},
			hasDescription() {
				const descriptions = this.product.data.description_i18n;
				const locale = this.$i18n.locale;

				return Boolean(descriptions?.[locale]);
			},
			getDescription() {
				if (this.hasDescription()) {
					const description =  this.$options.filters.translatable(
						'',
						this.product.data.description_i18n,
						this.$i18n.locale
					);

					return !this.isInModal ? description?.replace(/<\/?[^>]+(>|$)/g, "") : description;
				}
			},
			openModal() {
				this.$emit('showProductDetailModal', this.product);
			},
			quantityModifierChanged(modifier, newQuantity) {
				this.$emit("quantityModifierChanged", this.product, modifier, newQuantity);
			},
			emit(newQuantity) {
				this.$emit("quantityChanged", this.product, newQuantity);
				this.productQuantity = newQuantity;
				this.addRequiredModifiers();
			},
			addRequiredModifiers() {
				if(this.requiredModifiers.length > 0) {
					let valueStep = 1;
					this.requiredModifiers.forEach(modifier => {
						if (modifier.min_quantity == modifier.max_quantity) {
							valueStep = modifier.min_quantity;
						}
						this.quantityModifierChanged(modifier, valueStep * this.productQuantity);
					});
				}
			},
			handleAddToCart(product) {
				this.addToCartLoading = true;
				this.$emit('crossSellAddToCart', product)
			},
			handleAddToCartSuccess() {
				this.addToCartLoading = false;
			}
		}
	};
</script>

<style lang="scss" scoped>
	.text-blackish {
		color: $blackish;
	}
	.text-bold {
		font-weight: 600;
	}
	.card-container {
		border : 1px solid $light-grey;
	}
	.text-blackish {
		color: $grey-dark;
	}
	.text-grey-light {
		color: $grey-light;
	}
	.description-text {
		color: $secondary;
		font-weight: 600;
	}
	.no-border {
		border: none;
	}
	.modal-subcard {
		border : 1px solid $light-grey;
		border-radius: 8px;
	}
	.open-modal-button {
		font-size: 0.95rem !important;
	}
	hr {
		border-top:1px dotted $blackish;
	}
	.add-to-cart-button {
		background-color: $grey-dark !important;
		color: $grey-lighter!important;
		padding: 0;
	}

	@media (max-width: 1024px) {
		.quantity-picker {
			margin-top: 30px !important;
		}
		.open-modal-button-container {
			width: 100% !important;
			margin-left: 0 !important;
		}
		.open-modal-button {
			width: 100% !important;
		}
	}
	@media (max-width: 600px) {

		.open-modal-button {
			width: 100%;
		}
	}
</style>
