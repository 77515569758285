<template>
	<Page color="neutralLight" breadcrumbs no-sidebar show-combos>
		<v-container class="py-md-0 pt-md-6">
			<CrossSellModal
				v-if="isHardCrossSell"
				:visible.sync="crossSellModal.visible"
				:products="_crossSellProducts"
				:events="eventsForCrossSells"
				:fullscreen="$vuetify.breakpoint.smAndDown"
				scrollable
				max-width="1000"
				@continue="onCrossSellContinue"
			 />

			<!-- PRESENTATION -->
			<v-row>
				<v-col cols="12" md="5" class="mt-n6 pl-md-6">
					<!-- IMAGE -->
					<Sticky class="pt-6 py-md-6">
						<v-card flat>
							<v-img
								:src="details.data.image"
								:aspect-ratio="16 / 9"
								:class="{ 'grayscale': !hasValidPrice }"
							>
								<!-- PROMOTION CHIP -->
								<v-overlay
									v-if="details.data.promotion"
									absolute
									opacity="0"
									class="align-start justify-start pa-4"
								 >
									<v-card
										color="secondaryDark"
										class="promotion px-4 py-0 overline"
										dark
									 >
										<span v-text="$t('product.promotion')"></span>
									</v-card>
								</v-overlay>

								<template #placeholder>
									<v-skeleton-loader type="image" />
								</template>
							</v-img>
						</v-card>
					</Sticky>
				</v-col>
				<v-col cols="12" md="7" class="pr-md-6">
					<!-- PRODUCT INFO -->
					<v-card outlined>
						<template v-if="skeleton">
							<v-card-text>
								<v-skeleton-loader
									type="heading"
								></v-skeleton-loader>
								<v-skeleton-loader
									class="mt-4"
									type="paragraph"
								></v-skeleton-loader>
							</v-card-text>
						</template>
						<template v-else>
							<v-card-title
								class="text-left secondaryDark--text pb-3"
							>
								<span
									class="text-capitalize-fl"
									v-text="
										$options.filters.translatable(
											details.data.name,
											details.data.name_i18n
										)
									"
								></span>
							</v-card-title>
							<v-card-text v-if="hasValidPrice">

								<div v-if="hasQuantity" class="caption">
									<span
										v-if="
											details.data.min_quantity !== 0 &&
											details.data.min_quantity !== null &&
											details.data.max_quantity !== 0 &&
											details.data.max_quantity !== null
										"
										v-text="
											$t('product.quantityBoth', {
												min: details.data.min_quantity,
												max: details.data.max_quantity,
											})
										"
									></span>
									<span
										v-else-if="
											details.data.min_quantity !== 0 &&
											details.data.min_quantity !== null
										"
										v-text="
											$t('product.quantityMin', {
												min: details.data.min_quantity,
											})
										"
									></span>
									<span
										v-else-if="
											details.data.max_quantity !== 0 &&
											details.data.max_quantity !== null
										"
										v-text="
											$t('product.quantityMax', {
												max: details.data.max_quantity,
											})
										"
									></span>
								</div>
								<div v-if="details.data.priceVerifiedViaApi">
									<span
										v-if="hasMsrpDiscount(details)"
										style="text-decoration: line-through"
										class="mr-4"
										v-text="
											$options.filters.currency(
												details.data.msrp
											)
										"
									></span>
									<span
										v-text="
											$t('product.priceEach', {
												price: $options.filters.currency(
													details.data.price
												),
											})
										"
									></span>
								</div>
								<div
									class="d-flex align-center justify-space-between mt-3"
									v-if="details.data.priceVerifiedViaApi"
								>
									<h5
										class="text-h5 font-weight-bold"
										v-text="
											$options.filters.currency(
												details.data.price
											)
										"
									></h5>
								</div>
								<v-skeleton-loader v-else type="heading" />
							</v-card-text>
						</template>
					</v-card>

					<!-- DESCRIPTION -->
					<div class="mt-4">
						<v-skeleton-loader v-if="loading" type="paragraph" />
						<p class="body-1 font-weight-light" v-else>
							<span v-html="formattedDescription"></span>
								<a
									v-if="canShowAllDesc"
									class="secondaryDark--text"
									href
									@click.stop.prevent="showAllDesc = !showAllDesc"
									v-text="showAllDesc ? $t('product.readLess') : $t('product.readMore')"
								></a>
						</p>
					</div>

					<!-- EVENT CALENDAR -->
					<ProductCard
						v-if="!skeleton"
						:product.sync="details"
						:skeleton="skeleton"
						:event-group-ids="eventGroupIds"
					/>
				</v-col>
			</v-row>

			<div class="px-md-3 mb-8">
				<!-- CROSS-SELLS -->
				<div
					v-if="
						!isHardCrossSell &&
						_crossSellProducts.results.length > 0
					"
					class="mt-8"
				>
					<v-skeleton-loader v-if="loading" type="heading" />
					<h2
						v-else
						class="display-1 font-weight-light text-center"
						v-text="$t('tag.otherOptions')"
					></h2>

					<v-row class="mt-6">
						<v-col
							:key="index"
							v-for="(
								product, index
							) in _crossSellProducts.results"
							style="min-width: 20rem"
						>
							<Product
								:product="product"
								:skeleton="skeleton"
								:is-cross-sell="true"
								:show-calendar="!showCalendar"
								:day-selected.sync="selectedDay"
							/>
						</v-col>
					</v-row>
				</div>

				<!-- PROCEED TO CHECKOUT -->
			</div>

			<div :class="['sticky-container rounded py-5 px-5 mx-md-3 mx-0',$root.cart.data.itemCount ? 'active' : '']">
				<div class="button-container">
					<!-- TAGS -->
					<v-btn
						color="button"
						outlined
						x-large
						:to="{ name: 'home' }"
					>
						<v-icon left>mdi-arrow-left</v-icon>
						<span v-text="$t('btn.seeAllOptions')"></span>
					</v-btn>

					<v-btn
						data-testid="checkoutBtn"
						color="button"
						:disabled="$root.cart.data.itemCount === 0"
						class="ml-5"
						x-large
						@click="onProceedCheckout"
					>
						<v-icon left>mdi-cart</v-icon>
						<span v-text="$t('btn.proceedCheckout')"></span>
					</v-btn>
				</div>
			</div>
		</v-container>

		<InteractiveMapCrossSellModal
			v-if="hasInteractiveMap"
			:visible="showInteractiveMapModal"
			:next-action="() => $router.push({ name: 'cart' })"
			@modalClosed="showInteractiveMapModal = false" />

	</Page>
</template>

<script>
import Product from "@/components/Product";
import Page from "@/components/Page";
import Sticky from "@/components/Sticky";
import CrossSellModal from "@/components/CrossSellModal";
import Text from "@/utils/Text";
import {
	ProductModel,
	PaginationModel,
	EComService,
	EventBus,
} from "@connectngo/sdk";
import { hasMsrpDiscount } from "@/helpers/productHelper";
import ProductCard from "@/components/ProductCard";
import globalVariables from "@/global";
import InteractiveMapCrossSellModal from "@/components/InteractiveMap/InteractiveMapCrossSellModal";
import InteractiveMapMixin from "@/mixins/InteractiveMapMixin";
import EcomReskinMixin from '@/mixins/EcomReskinMixin'

export default {
	name: "ProductPage",

	components: {
		InteractiveMapCrossSellModal,
		Page,
		Sticky,
		Product,
		ProductCard,
		CrossSellModal,
	},

	mixins: [InteractiveMapMixin, EcomReskinMixin],

	metaInfo() {
		return {
			title: this.title,
			meta: [
				{
					property: "description",
					content: this.$options.filters.translatable(
						this.details.data.description,
						this.details.data.description_i18n,
						this.$i18n.locale
					),
				},
				{
					property: "og:title",
					content: this.$options.filters.translatable(
						this.details.data.name,
						this.details.data.name_i18n,
						this.$i18n.locale
					),
				},
				{ property: "og:url", content: window.location.href },
				{
					property: "og:description",
					content: this.$options.filters.translatable(
						this.details.data.description,
						this.details.data.description_i18n,
						this.$i18n.locale
					),
				},
				{ property: "og:image", content: this.details.data.image },
				{
					property: "twitter:image:src",
					content: this.details.data.image,
				},
			],
		};
	},

	data: () => ({
		skeleton: true,
		loading: true,
		details: new ProductModel(),
		crossSellProducts: new PaginationModel(),
		eventGroupIds: [],
		selectedDay: null,
		showCalendar: false,
		title: "",
		showAllDesc: false,
		eventsForCrossSells: {},
		crossSellModal: {
			visible: false,
		},
		eventEventBus: null,
		showInteractiveMapModal : false,
		selectedEventIdByEG: [],
	}),

	computed: {
		hasQuantity() {
			return (
				(this.details.data.min_quantity !== 0 &&
				this.details.data.min_quantity !== null) ||
				(this.details.data.max_quantity !== 0 &&
				this.details.data.max_quantity !== null)
			);
		},
		hasValidPrice() {
			return this.details.data.price !== null;
		},
		_crossSellProducts() {
			const product = new ProductModel();
			return this.skeleton
				? new PaginationModel([product, product, product])
				: this.crossSellProducts;
		},

		canShowAllDesc() {
			return this.description.length > 200 && this.windowWidth < 420;
		},

		windowWidth() {
			return window.innerWidth;
		},

		isHardCrossSell() {
			return this.$root.websiteConfig.data.fields.hard_cross_sell === "1";
		},

		description() {
			return this.$options.filters.translatable(
				"",
				this.details.data.description_i18n,
				this.$i18n.locale
			) || ''
		},

		formattedDescription() {
			const trimmed = Text.trimNotWithinWords(
				this.description.replace(/<\/?[^>]+(>|$)/g, ""),
				200
			);
			const formattedDescription = this.windowWidth < 420 && this.canShowAllDesc && !this.showAllDesc
				? trimmed.length === this.description.length
					? trimmed
					: trimmed + "..."
				: this.description;

			return formattedDescription.replace(/<h1>/g, '<h3>');
		}
	},

	methods: {
		hasMsrpDiscount,
		onCrossSellContinue() {
			this.$router.push({ name: "cart" });
		},

		onProceedCheckout() {

			if (
				this.isHardCrossSell && this._crossSellProducts.results.length > 0
			) {
				this.crossSellModal.visible = true;
			} else if(!this.isHardCrossSell && this.mustDisplayInteractiveMapCrossSellModal) {
				this.showInteractiveMapModal = true;
				globalVariables.interactiveMapModalShown = true;
			} else {
				this.$router.push({ name: "cart" });
			}
		},

		triggerGAEvent() {
			if (this.$gtm) {
				dataLayer.push({ ecommerce: null }); // Clear the previous ecommerce object.
				dataLayer.push({
					event: "view_item",
					ecommerce: {
						items: [
							{
								item_id: this.details.data.id,
								item_name: this.$options.filters.translatable(
									this.details.data.name,
									this.details.data.name_i18n,
									this.$i18n.locale
								),
								currency: this.$root.websiteConfig.data.tenant
									.currency.code,
								index: 1,
								price: this.details.data.price,
								quantity: 1,
							},
						],
					},
				});
			}
		},

		handleEventSelected(eventInfo) {
			if (eventInfo.eventGroup === null) {
				this.selectedEventID = eventInfo.id;
			} else {
				this.selectedEventIdByEG[eventInfo.eventGroup] = eventInfo.id;
			}
		},

		load() {
			this.loading = true;
			return Promise.all([
				new EComService().getProductDetails(this.$route.params.slug),
				new EComService().getCrossSell(this.$route.params.slug),
			])
				.then(([productDetails, crossSellProducts]) => {
					this.title = this.$options.filters.translatable(
						productDetails.data.name,
						productDetails.data.name_i18n,
						this.$i18n.locale
					);
					this.$route.meta.name = this.title;
					this.$breadcrumbs.refresh();
					this.crossSellProducts = crossSellProducts;
					Object.assign(this.details.data, productDetails.data);
					this.skeleton = false;

					const eventGroupIdArray = [];
					if (this.details.data.event_group) {
						eventGroupIdArray.push(
							this.details.data.event_group.id
						);
					}
					this.eventGroupIds = eventGroupIdArray;
					if (this.eventGroupIds.length > 0) {
						this.showCalendar = true;
					}

					this.triggerGAEvent();
				})
				.catch((reason) => this.$handleError(this, reason))
				.finally(() => (this.loading = false));
		},

		daySelected(value) {
			this.selectedDay = value;
		},
	},

	created() {
		this.title = this.$i18n.t("route.product_label");
		this.$route.meta.name = this.$i18n.t("state.loading");

		if (this.ecomReskinFfEnabled) {
			return this.redirectToProductV2UrlFromSlug(this.$route.params.slug)
		} else {
			this.load();
		}
	},
	mounted() {
		this.eventEventBus = EventBus.subscribe(
			"event",
			this.handleEventSelected
		);
	},
	destroyed() {
		this.eventEventBus.unsubscribe();
	},
};
</script>

<style>
.sticky-container {
	position: relative;
	background-color: #fff;
}

.sticky-container.active{
	position: sticky;
	position: -webkit-sticky;
	bottom: 0%;
	z-index: 2;
}

.sticky-container .button-container {
	display: flex;
	align-items: center;
	justify-content: center;
}

.grayscale {
	filter: grayscale(100%);
}

@media all and (max-width: 620px) {
	.sticky-container .button-container button, .sticky-container .button-container a{
		font-size: 0.7rem;
		padding: 0 10px !important;
	}
}

@media all and (max-width: 430px) {
	.sticky-container .button-container {
		display: grid;
	}

	.sticky-container .button-container button{
		margin-left: 0 !important;
		margin-top: 10px;
	}
}
</style>
