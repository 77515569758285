<template>
	<div>
		<v-container fluid>
			<v-row v-for="product in products" :key="product.id" class="align-center mt-0">

				<v-col cols="12" class="d-flex justify-space-between grey-dark pb-0">
					<div class="name">
						{{ displayQuantity(product.quantity) }} <span class="ml-3">{{ product.name }}</span>
					</div>
					<div v-if="product.price" class="price">
						{{ $options.filters.currencyWithoutCountry(calculateProductTotal(product)) }}
					</div>
				</v-col>

				<v-col cols="12" v-if="product.modifiers.length" class="pt-0">
					<div v-for="modifier in product.modifiers" :key="modifier.name" class="ml-6 mt-3 d-flex justify-space-between grey-dark pb-0">
						<span class="">{{ displayQuantity(modifier.quantity) }} {{ modifier.name }}</span>
						<span class="price">{{ $options.filters.currencyWithoutCountry(calculateModifierTotal(modifier)) }}</span>
					</div>
				</v-col>

				<v-col cols="12" v-if="product.combo_products.length" class="pt-0">
					<div v-for="combo in product.combo_products" :key="`${product.id}-${combo.id}`" class="ml-9 mt-3">
						{{ displayQuantity(combo.quantity * product.quantity) }} <span class="ml-3">{{ $options.filters.translatable(combo.name, combo.name_i18n, $i18n.locale) }}</span>
					</div>
				</v-col>
			</v-row>

			<hr class="mt-5 mb-5 divider">

			<v-row class="total" v-if="subTotal">
				<v-col>
					<div>{{ $t('product.subTotal') }} :</div>
				</v-col>
				<v-col class="text-right">
					<div>{{ $options.filters.currencyWithoutCountry(subTotal) }}</div>
				</v-col>
			</v-row>
		</v-container>
	</div>
</template>

<script>
	export default {
		name: "ProductsSubTotalList",
		props: {
			products: {
				type: Array,
				required : true,
			},
		},
		data() {
			return {
				subTotal: 0
			}
		},
		mounted(){
			this.calculateSubTotal();
		},
		watch: {
			products() {
				this.calculateSubTotal();
			}
		},
		methods: {
			calculateSubTotal() {
				let total = 0;
				this.products.forEach((product) => {
					const modifiers = [];
					let modifiersTotal = 0;
					if (product.modifiers.size > 0) {
						product.modifiers.forEach((item) => {
							const modifierPrice = item.modifier.price * item.quantity || 0;
							modifiers.push({
								quantity: item.quantity,
								name: item.modifier.name,
								price: item.modifier.price,
								required: item.modifier.required
							})
							modifiersTotal += modifierPrice;
						});
					}
					product.modifiers = modifiers;
					total += product.price + modifiersTotal;
				})
				this.subTotal =  total;
			},
			calculateProductTotal(product) {
				return product.price.toFixed(2);
			},
			calculateModifierTotal(modifier) {
				return modifier.price.toFixed(2) * modifier.quantity;
			},
			displayQuantity(quantity) {
				return `${quantity}x`;
			},
		}
	};
</script>

<style lang="scss" scoped>
	.grey-light {
		color: $grey-light;
	}
	.grey-dark {
		color: $grey-dark;
	}
	.price {
		font-weight: bold;
		color: $grey-dark;
	}
	.divider {
		border-top: 1px solid $grey-light;
	}
	.total {
		font-size: 1.3rem;
		font-weight: 600;
	}
</style>
